<template>

  <form @submit.prevent="handleSubmit">
    <div class="tw-mt-4">
      <ml-label required for="name">Name</ml-label>
      <div class="tw-mt-2">
        <ml-input-text v-model="name" id="name" name="name" required :class="errorClass('name')"/>
      </div>
    </div>
    <div v-if="errors['name'][0]" class="invalid-feedback">
      <strong>{{ errors['name'][0] }}</strong>
    </div>
    <div class="tw-mt-4">
      <ml-label required for="value">Value</ml-label>
      <div class="tw-mt-2">
        <ml-input-text v-model="value" id="value" name="value" required :class="errorClass('value')"/>
      </div>
    </div>
    <div v-if="errors['value'][0]" class="invalid-feedback">
      <strong>{{ errors['value'][0] }}</strong>
    </div>
    <div class="tw-mt-4">
      <button class="tw-mt-6 tw-px-8 tw-py-3 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-text-white tw-bg-black focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-black">
        <span>{{ buttonText }}</span>
      </button>
    </div>
  </form>
</template>

<script>

import MlLabel from "@/components/layout/form/MlLabel";
import MlInputText from "@/components/layout/form/MlInputText";
export default {
  components: {MlInputText, MlLabel},
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      name: '',
      value: '',
      errors: {
        name: [],
        value: [],

      },
    }
  },

  mounted() {
  },

  computed: {
    buttonText() {
      //return this.isEdit ? 'Update Address' : 'Create Address'
      return 'Add Budget'
    }
  },

  methods: {
    handleSubmit() {
      this.$emit('submitted', {
        name: this.name,
        value: this.value
      });
    },
    errorClass(column) {
      return [
        this.errors[column] && this.errors[column][0] ? 'is-invalid' : ''
      ]
    }
  }
}
</script>

<template>
<div>
  <spinner v-if="loading"></spinner>
  <div v-if="!loading">
      <div>
          <div>

            <button class="tw-flex tw-justify-center tw-w-full tw-px-6 tw-py-4 tw-text-base tw-font-medium"
                    :class="dvisible ? 'tw-text-gray-n4 tw-bg-gray-n1' : 'tw-bg-gray-n0'"
                    @click="dvisible = !dvisible; nvisible = !nvisible">
              View & delete budget..
            </button>

          </div>

          <b-collapse v-model="dvisible" id="collapse-1" class="mt-2" aria-expanded="true">
            <b-list-group v-for="budget in budgets" v-bind:key="budget.id">
                  <b-list-group-item class="d-flex justify-content-between align-items-center">
                    <p class="text-dark-50">{{budget.name}}</p>
                    <b-badge variant="light">{{budget.value | currency}}</b-badge>
                    <b-badge class="btn tw-bg-red" pill @click="deleteBudget(budget.id)" variant="danger">Delete</b-badge>

                  </b-list-group-item>
            </b-list-group>
            </b-collapse>
      </div>
    <hr class="tw-my-3">
      <div>
        <div>

          <button class="tw-flex tw-justify-center tw-w-full tw-px-6 tw-py-4 tw-text-base tw-font-medium"
                  :class="nvisible ? 'tw-text-gray-n4 tw-bg-gray-n1' : 'tw-bg-gray-n0'"
                  @click="nvisible = !nvisible; dvisible = !dvisible">
            Add Budget..
          </button>
        </div>

        <b-collapse v-model="nvisible" id="collapse-2" class="mt-2">
          <Budgets-form @submitted="createBudget"></Budgets-form>
        </b-collapse>
      </div>
  </div>
</div>
</template>

    <script>
      import BudgetsForm from './BudgetsForm.vue'
      import { mapGetters } from 'vuex'

      export default {
            name: 'Budgets',

            components: { BudgetsForm },

            data () {
              return {
              budgets: [],
              loading: true,
              dvisible: true,
              nvisible: false
              }
            },

            computed: {

                ...mapGetters({
                   // budget: 'budget/getBudget'
                }),

            },

            mounted () {
                this.fetchBudgets();
            },

            methods: {

              fetchBudgets () {
                      this.loading = true;
                this.$http.get('api/budget', { params: this.$route.query })
                          .then(({data})  => {
                              this.budgets = data.data
                          })
                          .catch(error => {
                              console.log('Error loading budgets data: ' + error)
                          }).finally(() =>
                          this.loading = false,
                          this.dvisible = true,
                          this.nvisible = false,
                          )
                  },
                  deleteBudget(id) {
                    this.$http.delete('/api/budget/' + id)
                        .then(({data}) => {
                            this.$toast.success(data.message, "Success", { timeout: 1000 });
                            this.fetchBudgets();
                        }).finally(() =>
                          this.$store.dispatch('budget/fetchBudget')
                          )
                  },
                    createBudget (data) {
                          //console.log(data);
                      this.$http.post('/api/budget', data)
                      .then(({ data }) => {
                          this.$toast.success(data.message, "Success")
                      })
                      .catch(({ response }) => {
                          console.log('error', response.data.errors);
                      }).finally(() =>
                                this.fetchBudgets(),
                                this.$store.dispatch('budget/fetchBudget')
                      )
                   }
             }
        };
    </script>


<template>

  <div>
    <b-modal v-model="modalShow" hide-footer>
      <template v-slot:modal-title>
        {{$t('user.my_budget')}} {{budget_total | currency }}
      </template>

      <Budgets />
    </b-modal>
    <!-- My Budget -->
    <div>
      <div class="tw-col-span-3 tw-rounded-xl tw-border-gray-n1 tw-border tw-p-6">
        <div>
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="tw-font-semibold tw-mb-3 tw-text-lg">{{$t('user.expenses_tracker')}}</div>
              <div class="tw-font-semibold tw-mb-3">
                {{this.budget_total | currency }}
              </div>
              <div class="row no-gutters align-items-center">
                <div class="col-auto">
                  <div class="mb-0 mr-3 font-weight-bold text-gray-800">{{this.overdue | percent}}</div>
                </div>
                <div class="col">
                  <div class="tw-w-full tw-bg-gray-n1 tw-rounded-full tw-h-2.5">
                    <div class="tw-bg-red tw-h-2.5 tw-rounded-full"
                         :style="progress"></div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="tw-flex tw-mt-4">
          <div class="tw-text-lg tw-text-red">
            Spent: {{this.order_total | currency }}
          </div>
          <a href="#" class="tw-ml-auto tw-text-sm tw-text-red tw-inline-flex tw-items-center"
             @click="modalShow = true">
            <edit2-icon class="tw-w-4 tw-h-4 tw-mr-2"/>
            Edit
          </a>
        </div>
      </div>
    </div>
  </div>



</template>


<script>

import Budgets from '../../components/Budgets.vue';
import { mapGetters } from 'vuex'
import { Edit2Icon } from "vue-feather-icons";

export default {
  middleware: 'auth',
  components: {
    Budgets,
    Edit2Icon,
  },
  data() {
    return {
      //budget: '100',
      modalShow: false,
      loading: false
    }
  },
  computed: {

    ...mapGetters({
      budgets: 'budget/getBudget',
      order_total: 'budget/getOrderTotal',
      budget_total: 'budget/getBudgetTotal',
    }),
    overdue: function () {
      return (this.order_total / this.budget_total);
    },
    progress: function () {
      return 'width:' + this.overdue * 100 + '%';
    },

    ProgressClassObject: function () {
      let value = 'progress-bar';
      if (this.overdue * 100 <= 33) {
        value = value + ' bg-info';
      } else if (this.overdue * 100 >= 33 && this.overdue * 100 <= 66) {
        value = value + ' bg-warning';
      } else {
        value = value + ' bg-danger'
      }
      console.log(value);
      return value;

    }


  },

  mounted() {
    this.$store.dispatch('budget/fetchBudget');
  },
  methods: {

    async loadData() {
      this.loading = true;
      await this.$store.dispatch('budget/fetchBudget');
      console.log('B B: ' + this.budget_total);
      this.loading = false;
    }

  }
}
</script>








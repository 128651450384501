<template>
  <div class="tw-max-w-9xl tw-mx-auto sm:tw-px-8 lg:tw-px-16 tw-px-3">
    <ml-grid>
      <div class="tw-col-span-5">
        <div class="tw-bg-red-100 tw-p-8 tw-rounded-xl tw-flex">
          <div class="tw-flex-grow">
            <h1 class="tw-font-serif tw-text-2rem">{{$t('user.your_wedding')}}</h1>
            <div class="tw-flex tw-text-red tw-text-sm tw-my-4" v-if="wedding.date">
              <calendar-icon class="tw-w-6 tw-h-6 tw-mr-3" />
              <span class="tw-mr-3">{{ dateFormat(wedding.date) }}</span>
              <!--              <map-pin-icon class="tw-w-6 tw-h-6 tw-mr-3"/>
              <span class="tw-mr-3">{{ '?' }}</span>-->
            </div>

            <router-link :to="{name: 'user.my-account'}"
              class="tw-mt-6 tw-inline-flex tw-justify-center tw-px-6 tw-py-2 tw-font-medium tw-rounded-xl tw-leading-relaxed tw-shadow-sm tw-text-red tw-bg-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
              <edit2-icon class="tw-w-5 tw-h-5 tw-mr-3" />
              {{$t('user.edit_wedding_info')}}
            </router-link>
          </div>
          <hearts class="tw--my-8" />
        </div>
      </div>
      <div class="tw-col-span-3">
        <div
          class="tw-rounded-xl tw-flex tw-justify-center tw-items-center tw-border tw-border-gray-n1 tw-text-black tw-h-full tw-flex-col">
          <span class="tw-font-serif tw-text-4rem tw-leading-relaxed tw-mb-3">{{wedding.count_booked_vendors}}</span>
          <span class="tw-text-sm">{{$t('user.active_vendors')}}</span>
          <router-link :to="{ name: 'user.myvendors'}" class="tw-border tw-inline-flex tw-mt-8 tw-font-semibold tw-leading-relaxed tw-py-3 tw-px-8 tw-text-black
           tw-border-black tw-rounded-xl tw-no-underline">{{$t('user.see_all_vendors')}}</router-link>
        </div>
      </div>
    </ml-grid>
    <div class="tw-border-b tw-mt-8 tw-border-gray-n0 tw-flex tw-justify-between tw-items-end">
      <nav class="tw--mb-px tw-flex tw-space-x-8 tw-leading-relaxed" aria-label="Tabs">
        <a @click.prevent="activeTab = 'tab-orders'" href="#"
          :class="[activeTab === 'tab-orders' ? 'tw-border-black tw-text-black' : 'tw-border-transparent tw-text-gray-n2 hover:tw-text-gray-n3 hover:tw-border-gray-n3', 'tw-no-underline tw-group tw-inline-flex tw-items-center tw-py-3 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm']">
          <span>{{$t('user.navbar.my_orders')}}</span>
        </a>
        <a @click.prevent="activeTab = 'tab-quotes'"
          :class="[activeTab === 'tab-quotes' ? 'tw-border-black tw-text-black' : 'tw-border-transparent tw-text-gray-n2 hover:tw-text-gray-n3 hover:tw-border-gray-n3', 'tw-no-underline tw-group tw-inline-flex tw-items-center tw-py-3 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm']"
          href="#">
          <span>{{$t('user.navbar.quote_requests')}}</span>
        </a>
        <a @click.prevent="activeTab = 'tab-budget'"
          :class="[activeTab === 'tab-budget' ? 'tw-border-black tw-text-black' : 'tw-border-transparent tw-text-gray-n2 hover:tw-text-gray-n3 hover:tw-border-gray-n3', 'tw-no-underline tw-group tw-inline-flex tw-items-center tw-py-3 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm']"
          href="#">
          <span>{{$t('user.my_expenses')}}</span>
        </a>
      </nav>

    </div>
    <div v-if="loading" class="tw-my-40">
      <RedSpinner />
    </div>
    <v-tabs v-else background-color="white" color="deep-purple accent-4" left v-model="activeTab">
      <v-tab-item value="tab-orders">
        <Orders :bookings="bookings" :getBookings="getBookings"/>
      </v-tab-item>
      <v-tab-item value="tab-quotes">
        <Quotes />
      </v-tab-item>
      <v-tab-item value="tab-budget">
        <BudgetPage />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import i18n from "@/i18n";
import MlGrid from "@/components/layout/MlGrid";
import { CalendarIcon, /*MapPinIcon, */Edit2Icon } from "vue-feather-icons";
import Hearts from "@/components/special-icons/Hearts";
import Quotes from './quotesPage.vue';
import Orders from './bookings/bookingsPage.vue';
import BudgetPage from "@/pages/users/budgetPage";
import RedSpinner from "@/components/RedSpinner"

export default {
  middleware: ['auth'],
  components: {
    BudgetPage,
    MlGrid,
    CalendarIcon,
    /*MapPinIcon,*/
    Edit2Icon,
    Hearts,
    Quotes,
    Orders,
    RedSpinner,
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },

  data() {
    return {
      wedding: {
        count_booked_vendors: 0,
        date: null
      },
      loading: false,
      activeTab: 'tab-orders',
      bookings: [],
    }
  },
  mounted() {
    this.getWedding();
    this.getBookings();
  },
  methods: {
    getWedding() {
      this.loading = true;

      let uri = '/api/events';
      this.$http.get(uri)
        .then(({ data }) => {
          this.wedding = data.data
        }).catch(error => {
          console.log('Error loading data: ' + error)
        })
    },
    getBookings() {
      this.loading = true;

      let uri = 'api/orders';
      this.$http.get(uri).then((response) => {
        this.bookings = response.data.data;
        this.loading = false;
      });
    },

  },

  metaInfo() {
    return { title: i18n.t('general.my_dashboard') }
  },

}
</script>
